import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authApi } from './auth/authApi';
import { auth } from './auth/authReducer';
import { dashboardApi } from './dashboard/dashboardApi';
import { documentApi } from './document/documentApi';
import { facultyApi } from './faculty/facultyApi';
import { groupApi } from './group/groupApi';
import {journalApi} from "./journal/journalApi";
import {practiceApi} from "./practice/practiceApi";
import { sessionApi } from './session/sessionApi';
import { statementApi } from './statement/statementApi';
import { studentApi } from './student/studentApi';
import { userApi } from './user/userApi';
import {attestationApi} from "./attestation/attestationApi";
import {courseApi} from "./course/courseApi";
import {employeeApi} from "./employee/employeeApi";
import {chairApi} from "./chair/chairApi";
import {umuApi} from "./umu/umuApi";
import semesterReducer from "./semester/semester-reducer";
import userReducer from "./user/user-reducer";

const reducer = combineReducers({
  auth: auth.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [attestationApi.reducerPath] : attestationApi.reducer,
  [chairApi.reducerPath] : chairApi.reducer,
  [courseApi.reducerPath] : courseApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [facultyApi.reducerPath]: facultyApi.reducer,
  [groupApi.reducerPath]: groupApi.reducer,
  [journalApi.reducerPath]: journalApi.reducer,
  [practiceApi.reducerPath]: practiceApi.reducer,
  [sessionApi.reducerPath]: sessionApi.reducer,
  [statementApi.reducerPath]: statementApi.reducer,
  [studentApi.reducerPath]: studentApi.reducer,
  [umuApi.reducerPath]: umuApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  semesterReducer, // Редюсер семестров
  userReducer // Редюсер пользователя
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      attestationApi.middleware,
      chairApi.middleware,
      courseApi.middleware,
      dashboardApi.middleware,
      documentApi.middleware,
      employeeApi.middleware,
      facultyApi.middleware,
      groupApi.middleware,
      journalApi.middleware,
      practiceApi.middleware,
      sessionApi.middleware,
      statementApi.middleware,
      studentApi.middleware,
      umuApi.middleware,
      userApi.middleware
    ),
});
