import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/Account' }),
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    singin: builder.mutation({
      query: ({ username, password }) => ({
        url: `/Login`,
        method: 'POST',
        params: { loginPair: `${username}:${password}` },
      }),
    }),
  }),
});

export const { useSinginMutation } = authApi;
