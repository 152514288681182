import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const umuApi = createApi({
  reducerPath: 'umuApi',
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({
    baseUrl: '/Umu',
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    getUmuSummary: builder.query({
      query: () => ({
        url: `/UmuSummary`,
        method: 'GET'
      }),
    }),
  }),
});

export const {
  useGetUmuSummaryQuery
} = umuApi;
