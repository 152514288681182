import { Grid } from "@mui/material"
import React from "react"
import logo from "./img/logo.svg"
import { Link } from "react-router-dom"
import { SupportRounded } from "@mui/icons-material"
import UserMenu from "./components/user_menu"
import NavbarInfo from "./components/navbar_info"
import "./styles.scss"
import Semester from "./components/semester";

const Navbar = () => {
  return (
    <>
      <header>
        <div className="container">
          <Grid container spacing={2}>
            <Grid item xl={8} lg={8}>
              <div className="menu-wrapper">
                <Link to={'/'}><img src={logo} alt="Логотип"/></Link>
                <Semester/>
              </div>
            </Grid>
            <Grid item xl={4} lg={4}>
              <UserMenu />
            </Grid>
          </Grid>
        </div>
      </header>
      {/* <NavbarInfo */}
      {/*  type="info" */}
      {/*  message="Коллектор статистики не включён." */}
      {/* /> */}
    </>
  )
}

export default Navbar
