import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Button,
    CircularProgress,
    Backdrop,
    CardContent,
    FormControl, InputLabel,
} from '@mui/material';
// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import AddIcon from "@mui/icons-material/Add";
import StarIcon from '@mui/icons-material/Star';
import ArticleIcon from '@mui/icons-material/Article';
import PrintIcon from "@mui/icons-material/Print";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StopIcon from '@mui/icons-material/Stop';
import {useGetListFacultySessionsQuery} from "../store/session/sessionApi";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import {Alert} from "@mui/lab";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import simpleSort from "../utils/copyObject";

export default function SessionsPage() {
    const {data} = useGetListFacultySessionsQuery();
    const [courses, setCourses] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedCourseFilter, setSelectedCourseFilter] = useState(null);
    const [selectedGroupFilter, setSelectedGroupFilter] = useState(null);
    const [scheduleIsLoading, setScheduleIsLoading] = useState(false);

    useEffect(() => {
        console.log(data);
        const filteredCourses = data?.data?.map((item) => buildCourseName(item.courseNumber, item.programOfEducationName));
        const tempCourses = [];
        
        filteredCourses?.forEach((item) => {
            if (tempCourses.find((c) => c === item) === undefined) {
                tempCourses.push(item);
            }
        });
        
        const tempGroups = [];
        data?.data?.forEach((item) => {
            item.groups.forEach((g) => {
                if (tempGroups.find((tg) => tg === g) === undefined) {
                    tempGroups.push(g);
                }
            })
        })
        
        setGroups(simpleSort(tempGroups));
        setCourses(simpleSort(tempCourses));
    }, [data]);
    
    function buildCourseName(courseNumber, programOfEducationName) {
        return `${courseNumber} курс, ${programOfEducationName}`;
    }
    
    function printSchedule(sessionId, retakes = false) {
        setScheduleIsLoading(true);
        fetch(`/Reports/Session${retakes ? 'Retakes' : ''}Schedule?sessionId=${sessionId}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json", 
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                    "semesterid": localStorage.getItem("selectedSemesterId")
                }
            }).then(response => {
            setScheduleIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Расписание сессии.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }

    function setFilterByCourse(value) {
        setSelectedCourseFilter(value);
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Сессии`} />

            <Container>
                {scheduleIsLoading
                ? <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={scheduleIsLoading}
                    >
                    <CircularProgress color="inherit" />
                    </Backdrop>
                : <></>}
                <Stack
                    padding="120px 0px 0px 100px"
                    direction="column"
                    spacing={2}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined
                            ? <CircularProgress/>
                            : <></>} Сессии
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography variant={'h6'}>Фильтры</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="course-label" size={'small'}>Курс</InputLabel>
                                    <Select labelId={'course-label'}
                                            size={'small'}
                                            label={'Курс'}
                                            onChange={(event) => {setFilterByCourse(event.target.value)}}>
                                        <MenuItem key={'0'} value={(null)}>Все</MenuItem>
                                        {courses?.map((item) => {
                                            return <MenuItem key={item} value={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="group-label" size={'small'}>Группа</InputLabel>
                                    <Select labelId={'group-label'}
                                            size={'small'}
                                            label={'Группа'}
                                            onChange={(event) => setSelectedGroupFilter(event.target.value)}>
                                        <MenuItem key={'0'} value={(null)}>Все</MenuItem>
                                        {groups.sort((left,right) => left > right ? 1 : -1).map((item) => {
                                            return <MenuItem key={item} value={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </CardContent>
                    </Card>

                    <Card>
                        <Scrollbar>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Курс</TableCell>
                                        <TableCell>Образовательная программа</TableCell>
                                        <TableCell>Информация</TableCell>
                                        <TableCell>Операции</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.data?.filter((item) => (selectedCourseFilter === null || buildCourseName(item.courseNumber, item.programOfEducationName) === selectedCourseFilter)
                                                            && (selectedGroupFilter === null || item.groups.find((g) => g === selectedGroupFilter) !== undefined))
                                        .map((item) => {
                                        return (<TableRow>
                                            <TableCell>
                                                {item.courseNumber}
                                            </TableCell>
                                            <TableCell>
                                                {item.programOfEducationName}
                                                <Typography fontSize="x-small">{item.groups.join(', ')}</Typography>
                                                <br />
                                                <Stack spacing={1}>
                                                    {item.activeReviews?.map((review) => {
                                                        return <Alert variant={"filled"} color={"warning"}>{review}</Alert>
                                                    })}
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" alignItems="center">
                                                    {
                                                        item.sessionId === null
                                                            ? <>Сессия не создана</>
                                                            : <>
                                                                Зачетная неделя:<br/>
                                                                {item.checksStartDate} - {item.checksEndDate} <br/><br/>

                                                                Экзамены:<br/>
                                                                {item.examsStartDate} - {item.examsEndDate}<br/><br/>

                                                                Каникулы:<br/>
                                                                {item.holidayStartDate} - {item.holidayEndDate}
                                                            </>
                                                    }

                                                    <Stack spacing={2}>
                                                        {
                                                            item.approveStatus === 0
                                                                ? <>
                                                                        <Typography fontSize="small"><CheckIcon color="success" fontSize="small"/> Основная информация заполнена</Typography>
                                                                </>
                                                                : <></>
                                                        }

                                                        {
                                                            item.approveStatus === 0
                                                                ? <>
                                                                    {item.scheduleFulfilled
                                                                    ? <>
                                                                        <Typography fontSize="small"><CheckIcon color="success" fontSize="small"/> Расписание заполнено</Typography>
                                                                    </>
                                                                    : <>
                                                                        <Typography fontSize="small"><ReportProblemIcon color="warning" fontSize="small"/> Расписание не заполнено</Typography>
                                                                    </>}
                                                                </>
                                                                : <></>
                                                        }

                                                        {
                                                            item.approveStatus === 0
                                                                ? <>
                                                                    <Typography fontSize="small"><ReportProblemIcon color="warning" fontSize="small"/> Не отправлено на утверждение</Typography>
                                                                </>
                                                                : <></>
                                                        }

                                                        {
                                                            item.approveStatus === 1
                                                                ? <>
                                                                    <Typography fontSize="small"><HourglassBottomIcon color="success" fontSize="small"/> Отправлено на утверждение в УМУ</Typography>
                                                                </>
                                                                : <></>
                                                        }

                                                        {
                                                            item.approveStatus === 2
                                                                ? <>
                                                                    <Typography fontSize="small"><HourglassBottomIcon color="success" fontSize="small"/> Отправлено на утверждение в диспетчерскую</Typography>
                                                                </>
                                                                : <></>
                                                        }

                                                        {
                                                            item.approveStatus === 3
                                                                ? <>
                                                                    <Typography fontSize="small"><ReportProblemIcon color="warning" fontSize="small"/> Необходимо внести изменения в расписание</Typography>
                                                                </>
                                                                : <></>
                                                        }

                                                        {
                                                            item.approveStatus === 7
                                                                ? <>
                                                                    <Typography fontSize="small"><ReportProblemIcon color="warning" fontSize="small"/> Необходимо внести изменения в информацию о сессии</Typography>
                                                                </>
                                                                : <></>
                                                        }
                                                    </Stack>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    item.sessionId !== null
                                                        ? <>
                                                            {item.approveStatus >= 99 // approved
                                                                ? <>
                                                                    <Button startIcon={<StarIcon/>}
                                                                            href={`/outside-plan-passing-list/${item.sessionId}`}>Управление внеплановыми
                                                                        сдачами</Button> <br/>
                                                                    <Button startIcon={<ArticleIcon/>}
                                                                            href={`/managestatements/${item.sessionId}`}>Управление
                                                                        ведомостями</Button> <br/>
                                                                    <Button startIcon={<PrintIcon/>}
                                                                            onClick={() => printSchedule(item.sessionId, false)}>Печать расписания</Button> <br/>
                                                                    <Button startIcon={<PrintIcon/>}
                                                                            onClick={() => printSchedule(item.sessionId, true)}>Печать расписания пересдач</Button> <br/>
                                                                    <Button startIcon={<CalendarMonthIcon/>}
                                                                            href={`/editsessionretakesschedule/${item.sessionId}`}>Редактировать
                                                                        расписание пересдач</Button> <br/>
                                                                    <Button startIcon={<StopIcon/>}
                                                                              disabled={item.hasActiveStopRequests}
                                                                              href={`/create-session-stop-request/${item.sessionId}`}>Приостановить
                                                                            сессию</Button>
                                                                </>
                                                                : <>
                                                                    {item.approveStatus === 3 || item.approveStatus === 0 || item.approveStatus === 7 // not sent for approving or need fixes from dispatcher/UMU
                                                                        ? <>
                                                                            <Button startIcon={<StarIcon/>}
                                                                                    href={`/outside-plan-passing-list/${item.sessionId}`}>Управление
                                                                                внеплановыми сдачами</Button> <br/>
                                                                            {item.approveStatus !== 3
                                                                                ? <><Button startIcon={<EditIcon/>}
                                                                                          href={`/createsession/${item.courseId}`}>Редактировать
                                                                                    сессию</Button> <br/></>
                                                                                : <></>}

                                                                            {item.approveStatus !== 7
                                                                                ? <>
                                                                                    <Button startIcon={<CalendarMonthIcon/>}
                                                                                            href={`/editsessionschedule/${item.sessionId}`}>Редактировать
                                                                                        расписание экзаменов и зачётов</Button>
                                                                                    <br/>
                                                                                </>
                                                                                : <></>}
                                                                            
                                                                            {item.approveStatus !== 7
                                                                                ? <><Button startIcon={<CalendarMonthIcon/>}
                                                                                            href={`/editsessionretakesschedule/${item.sessionId}`}>Редактировать
                                                                                    расписание пересдач</Button> <br/></>
                                                                                : <></>}

                                                                            {item.scheduleFulfilled
                                                                            ? <><Button startIcon={<CheckIcon/>}
                                                                                        href={`/approvesession/${item.sessionId}`}>Отправить на утверждение</Button> <br/>
                                                                                </>
                                                                            : <></>}
                                                                        </>
                                                                        : <></>}
                                                                </>
                                                            }
                                                        </>
                                                        : (
                                                            item.canCreate
                                                                ? <Button startIcon={<AddIcon/>}
                                                                          variant="contained"
                                                                          href={`/createsession/${item.courseId}`}>Создать</Button>
                                                                : <Typography color="orange" fontSize="small">Для создания
                                                                    сессии необходимо сдать все ведомости за прошлый семестр на
                                                                    факультет</Typography>
                                                        )
                                                }
                                            </TableCell>
                                        </TableRow>)
                                    })}
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}