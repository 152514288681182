import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, ButtonGroup,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {FileOpen, Print, SaveOutlined} from "@mui/icons-material";
import {
    useGetOutsidePlanPassingDetailsQuery,
    useSendOutsidePlanPassingForApprovalMutation
} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";
import EditIcon from "@mui/icons-material/Edit";
import getApproveStatusName from "../utils/getApproveStatusName";
import IconButton from "@mui/material/IconButton";

export default function OutsidePlanPassingDetailsPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useGetOutsidePlanPassingDetailsQuery({id});
    const [sendPassingForApproval] = useSendOutsidePlanPassingForApprovalMutation();
    const isAdmin = false; // TODO
    
    function canEdit() {
        const status = data?.data?.approveStatus;
        return status === 0 || status === 3 || status === 7;
    }
    
    async function sendForApproval() {
        setIsSaving(true);
        await sendPassingForApproval({data: {outsidePlanPassingId: id}}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                // navigate(`/outside-plan-passing-details/${id}`);
                window.location.reload();
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        }).catch(() => {
            alert('Ошибка');
            setIsSaving(false);
        });
    }

    function getStatementsTable(controlType) {
        const statements = data?.data?.statements.filter((item) =>
            controlType === 1
                ? item.controlType === 1 || item.controlType === 2
                : controlType === 3
                    ? item.controlType === 3 || item.controlType === 4
                    : item.controlType === 0
        );

        console.log(`Для типа контроля ${controlType} найдены:`, statements)

        return statements?.length > 0
            ? <><Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Дисциплина</TableCell>
                        <TableCell>ФИО преподавателя</TableCell>
                        <TableCell>Номер ведомости</TableCell>
                        <TableCell>Место и время проведения</TableCell>
                        <TableCell>Дата закрытия</TableCell>
                        <TableCell>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {statements?.map((item) => {
                        return <TableRow>
                            <TableCell>{item.disciplineName} {item.controlType === 2 ? '(Дифф.)' : ''}</TableCell>
                            <TableCell>{item.employees.map((item) => item.value).join(', ')}</TableCell>
                            <TableCell>{item.statementNumber}</TableCell>
                            <TableCell>{item.eventPlace}<br/>{toDateString(item.eventDate)}</TableCell>
                            <TableCell>{item.closingDate === null ? 'Не закрыта' : toDateString(item.closingDate)}</TableCell>
                            <TableCell>
                                <Button startIcon={<FileOpen/>}
                                        href={`/statementresults/${item.statementId}`}
                                        target={'_blank'}>Подробности</Button>
                                {canEdit()
                                ? <>
                                        <Button startIcon={<EditIcon/>}
                                                href={`/editsessiondisciplinedetail/${item.sessionDisciplineDetailId}`}>Редактировать</Button>
                                    </>
                                : <></>}
                                <Button startIcon={<Print/>}>Справочная ведомость</Button>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table></>
            : <Typography>Нет записей</Typography>
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function toDateString(date) {
        if (date === null)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Детали внеплановой сдачи сессии`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress/> : <></>}
                            Детали внеплановой сдачи сессии
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant={'h5'}>Информация о сдаче</Typography>
                                <Stack spacing={0}>
                                    <Typography><strong>Факультет: </strong>{data?.data?.facultyName}</Typography>
                                    <Typography><strong>Курс: </strong>{data?.data?.courseName}</Typography>
                                    <Typography alignItems={'center'}><strong>Номер распоряжения: </strong>{data?.data?.orderNumber}
                                        {isAdmin || false // data?.data?.approveStatus === 0
                                        ? <IconButton size={'small'} color={'primary'}><EditIcon /></IconButton>
                                        : <></>}
                                    </Typography>
                                    <Typography><strong>Статус
                                        утверждения: </strong>{getApproveStatusName(data?.data?.approveStatus)}
                                    </Typography>
                                </Stack>

                                <Divider/>

                                <Typography variant={'h5'}>Общий cписок студентов (участвующих в этой
                                    сдаче)</Typography>
                                <ol>
                                    {data?.data?.students.map((item) => {
                                        return <li>{item.value}</li>
                                    })}
                                </ol>

                                <Divider/>

                                <Typography variant={'h5'}>Список ведомостей (которые относятся к этой
                                    сдаче)</Typography>

                                <Typography variant={'h6'}>Экзамены</Typography>
                                {getStatementsTable(0)}

                                <Typography variant={'h6'}>Зачёты</Typography>
                                {getStatementsTable(1)}

                                <Typography variant={'h6'}>Курсовые работы/проекты</Typography>
                                {getStatementsTable(3)}

                                <Divider/>

                                <Typography variant={'h6'}>История замечаний</Typography>
                                <Table size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Детали замечания</TableCell>
                                            <TableCell>Дата</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.data?.reviews.map((item) => {
                                            return <TableRow>
                                                <TableCell>{item.text}</TableCell>
                                                <TableCell>{item.date}</TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>

                                {data?.data?.approveStatus === 0 || data?.data?.approveStatus === 3 || data?.data?.approveStatus === 7
                                    ? <>
                                        <Button variant={'contained'} 
                                                disabled={isSaving}
                                                startIcon={isSaving ? <CircularProgress size={15} /> : <SaveOutlined />}
                                                onClick={() => sendForApproval()}>Отправить на утверждение</Button>
                                    </>
                                    : <></>}
                            </Stack>
                        </CardContent>
                    </Card>
                    <br/>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}