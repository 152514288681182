import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {GeneralLayout} from "../layouts/GeneralLayout";
import PageTitle from "../components/PageTitle";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container, FormControl, FormControlLabel, Grid, InputLabel,
    Stack, ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import {DeleteOutline, PersonRemove, RemoveCircle, RemoveFromQueue} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {
    useEditSessionDisciplineDetailMutation,
    useOutsidePlanPassingDisciplineDetailsQuery
} from "../store/session/sessionApi";
import copyObject from "../utils/copyObject";
import getSessionControlTypeName from "../utils/getSessionControlTypeName";

export default function EditSessionDisciplineDetailPage() {
    const {id} = useParams();
    const roles = (localStorage.getItem('roles') ?? '').split(',');
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useOutsidePlanPassingDisciplineDetailsQuery({id});
    const [availableEmployees, setAvailableEmployees] = useState([]);
    const [existingEmployees, setExistingEmployees] = useState([]);
    const [newEmployees, setNewEmployees] = useState([]);
    const [editSessionDisciplineDetail] = useEditSessionDisciplineDetailMutation();
    const [request, setRequest] = useState({
        employeesToRemove: [],
        sessionDisciplineDetailId: id,
        newEmployees: [],
        eventDate: null,
        eventTime: null,
        eventPlace: null,
        statementNumber: null
    });

    const educationForm = 1;
    const approveStatus = 0;
    const isAdmin = roles.find((item) => item === 'AttNewAdmin') !== undefined;
    
    useEffect(() => {
        if (data?.data === null || data === undefined) 
            return;
        
        setAvailableEmployees(copyObject(data?.data?.availableEmployees));
        setExistingEmployees(copyObject(data?.data?.employees));
        request.eventDate = formatDate(new Date(data?.data?.eventDate));
        request.eventTime = formatTime(new Date(data?.data?.eventDate));
        request.eventPlace = data?.data?.eventPlace;
        request.statementNumber = data?.data?.statementNumber;
        
        console.log(`eventDate после иниц`, request.eventDate)
        console.log(`eventTime после иниц`, request.eventTime)
    }, [data])

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }
    
    function formatTime(dateObj) {
        return `${withZero(dateObj.getHours())}:${withZero(dateObj.getMinutes())}`;
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);
        
        request.newEmployees = newEmployees.map((item) => parseInt(item.key, 10));
        console.log(`eventDate`, request.eventDate)
        console.log('eventTime', request.eventTime)
        console.log(request)

        await editSessionDisciplineDetail({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate(`/outside-plan-passing-details/${data?.data?.outSidePlanPassingId}`);
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        }).catch(() => {
            alert('Ошибка');
            setIsSaving(false);
        });
    };
    
    function applyVisualChanges() {
        setRefresh(!refresh);
    }
    
    function addNewEmployee(value) {
        newEmployees.push(value);
        console.log(newEmployees);
        applyVisualChanges();
    }

    function removeNewEmployee(item) {
        newEmployees.splice(newEmployees.indexOf(item), 1)
        applyVisualChanges();
    }
    
    function removeExistingEmployee(item) {
        if (!isAdmin)
            return;
        
        request.employeesToRemove.push(parseInt(item.key, 10));
        existingEmployees.splice(existingEmployees.indexOf(item), 1);
        applyVisualChanges()
    }
    
    function setEventDate(value) {
        console.log(`Устанавливаем дату`, value);
        request.eventDate = value;
    }

    function setEventTime(value) {
        console.log(`Устанавливаем время`, value);
        request.eventTime = value;
    }

    function setEventPlace(value) {
        request.eventPlace = value
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Редактировать сдачу`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress/> : <></>}
                            Редактировать дисциплину внеплановой сдачи
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant={'h6'}>{getSessionControlTypeName(data?.data?.controlType)} по дисциплине "{data?.data?.disciplineName}" у 
                                    группы {data?.data?.groupName}</Typography>

                                {data?.data?.outsidePlanPassingApproveStatus === 0 
                                || data?.data?.outsidePlanPassingApproveStatus === 3 
                                || data?.data?.outsidePlanPassingApproveStatus === 7
                                    ? <>
                                        <Typography><strong>Номер ведомости</strong></Typography>
                                        <Grid>
                                            <TextField size={'small'}
                                                       onChange={(event) => {request.statementNumber = event.target.value}}
                                                       defaultValue={data?.data?.statementNumber} />
                                        </Grid>
                                        
                                        <Typography><strong>Преподаватели</strong></Typography>
                                        {isAdmin
                                            ? <ol>
                                                {existingEmployees?.sort((l,r) => l.value > r.value)
                                                    .map((item) => {
                                                        return <li>{item.value} <IconButton size={'small'}
                                                                                            onClick={() => removeExistingEmployee(item)}
                                                                                        color={'error'}><PersonRemove/></IconButton>
                                                        </li>
                                                    })}
                                            </ol>
                                            : <></>
                                        }

                                        <Typography>Добавить преподавателя</Typography>
                                        <Select size={'small'} onChange={(event) => addNewEmployee(event.target.value)}>
                                            {availableEmployees?.sort((l,r) => l.value > r.value).map((item) => {
                                                return <MenuItem key={item.key} value={item}>{item.value}</MenuItem>
                                            })}
                                        </Select>

                                        <ol>
                                            {newEmployees.map((item) => {
                                                return <li>{item.value} <IconButton size={'small'}
                                                                                    onClick={() => removeNewEmployee(item)}
                                                                                    color={'error'}><PersonRemove/></IconButton>
                                                </li>
                                            })}
                                        </ol>

                                        <Typography><strong>Дата и место проведения</strong></Typography>
                                        <Grid container>
                                        <Grid item lg={2}>
                                                <FormControl>
                                                    <Typography>Дата</Typography>
                                                    <TextField type={'date'} 
                                                               size={'small'}
                                                               onChange={(event) => setEventDate(event.target.value)}
                                                               defaultValue={formatDate(new Date(data?.data?.eventDate))}/>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={2}>
                                                <FormControl>
                                                    <Typography>Время</Typography>
                                                    <TextField type={'time'} 
                                                               size={'small'}
                                                               onChange={(event) => setEventTime(event.target.value)}
                                                               defaultValue={formatTime(new Date(data?.data?.eventDate))}/>
                                                </FormControl>
                                            </Grid>

                                            <Grid item lg={2}>
                                                <FormControl>
                                                    <Typography>Место проведения</Typography>
                                                    <TextField type={'text'} 
                                                               size={'small'} 
                                                               onChange={(event) => setEventPlace(event.target.value)}
                                                               defaultValue={data?.data?.eventPlace}/>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </>
                                    : <></>}

                                <Stack justifyContent="space-between">
                                    {
                                        !isSaving
                                            ? <Button variant="contained"
                                                      disabled={data === null || data === undefined}
                                                      onClick={handleSave}>Сохранить</Button>
                                            : <CircularProgress/>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}